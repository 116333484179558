import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { Player } from 'video-react';

import NavBar from "../../component/navbar/navbar"
import Footer from "../../component/footer/footer"
import Caroussel from "../../component/caroussel/caroussel"

import styles from "./accueil.module.scss"
import { endurence, gym, halterophilie, accompagnement, depassement_de_soi, partage } from "../../assets/repeat"
import { presentation_video } from "../../assets/video";
import { tro_circle_home_page } from "../../assets/circle"
import { caroussel_home_page } from "../../assets/caroussel"
import { endurence_sq, gym_sq, haltero_sq, kev_bienvenue } from "../../assets/accueil"
import { one, two, three } from "../../assets/number"


function AccueilPage() {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    return(
        <div>
            <NavBar />
            <section className={styles.firstsection}>
                <div className={styles.media}>
                    <Player poster={kev_bienvenue}>
                        <source src={presentation_video} />
                    </Player>
                </div>
                <div className={styles.content}>
                    <h3>qui sommes-nous ?</h3>
                    <p>CrossFit Les Herbiers est la première box affiliée qui ouvre ses portes dans la zone Ekho 4 des Herbiers. Un espace de 250m2 pour pouvoir pratiquer votre sport de manière encadrée et en toute sécurité.</p>
                    <NavLink to="/la-box">En savoir plus sur la box Crossfit Les Herbiers</NavLink>
                </div>
            </section>
            <section className={styles.secondsection}>
                <img src={tro_circle_home_page} alt=""/>
            </section>
            <Caroussel images={caroussel_home_page}/>
            <section className={styles.thirdsection}>
                <div className={styles.firstimg}>
                    <img src={depassement_de_soi} alt="Dépassement de soi"/>
                </div>
                <div className={styles.secondimg}>
                    <img src={partage} alt="Partage"/>
                </div>
                <div className={styles.thirdimg}>
                    <img src={accompagnement} alt="Accompagnemet"/>
                </div>
            </section>
            <section className={styles.forthsection}>
                <h3 className={width < 1200 ? width < 600 ? styles.h3phone : styles.h3tab : undefined}>QU’EST CE QUE LE CROSSFIT ?</h3>
                <div className={width < 1200 ? width < 600 ? styles.paragraphcontainerphone : styles.paragraphcontainertab : styles.paragraphcontainer}>
                    <p>Le CrossFit est une discipline pluridisciplinaire qui se caractérise par des mouvements fonctionnels (pousser, soulever, tirer, sauter) et se présente sous forme de « W.O.D », une séance collective d’une heure. L’idée est de concentrer l’activité de manière intensive, pour en tirer les plus grands bénéfices. Chaque séance est composée de 10 participants maximums afin de proposer un accompagnement adapté et une évolution en toute sécurité. Cette pratique sportive permet de réaliser des objectifs spécifiques mais surtout elle contribue au bien-être et à la santé.</p>
                    <div className={styles.bloc}/>
                </div>
                <div className={width > 1320 ? styles.content : styles.contentphone}>
                    <div className={styles.one}>
                        <img src={endurence_sq} alt="" className={styles.image} />
                        <img src={one} alt="" className={styles.number} />
                        <img src={endurence} alt="" className={styles.repeat} />
                    </div>
                    <div className={styles.two}>
                        <img src={gym_sq} alt="" className={styles.image} />
                        <img src={two} alt="" className={styles.number} />
                        <img src={gym} alt="" className={styles.repeat} />
                    </div>
                    <div className={styles.three}>
                        <img src={haltero_sq} alt="" className={styles.image} />
                        <img src={three} alt="" className={styles.number} />
                        <img src={halterophilie} alt="" className={styles.repeat} />
                    </div>
                </div>
            </section>
            <section className={width > 1024 ? styles.fifthsection : width > 450 ? styles.fifthsectiontab : styles.fifthsectionphone }>
                <h2 >ENVIE DE FAIRE PARTIE DE L’AVENTURE CROSSFIT LES HERBIERS ?</h2> 
                <NavLink to="/contacts">Remplir le formulaire de contact</NavLink>
            </section>
            <section className={width > 800 ? styles.sixthsection : styles.sixthsectionphone}>
                {width > 1024 ? <div className={styles.border} /> : undefined}
                <div className={styles.textlarge}>
                    <h3 >voir le planning</h3>
                    <h3 >voir le planning</h3>
                    <h3 >voir le planning</h3>
                </div>
                <NavLink to="/planning">voir le planning</NavLink>
                <h3  className={styles.textsmall}>voir le plannnig</h3>
                <div className={styles.border}>   
                    <NavLink to="/planning">
                        <div className={styles.halfarrow}/>
                    </NavLink>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default AccueilPage