import { Swiper, SwiperSlide} from "swiper/react"
import {Navigation} from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import styles from "./caroussel.module.scss"

function Caroussel(props) {

    return(
        <Swiper
            modules={[Navigation]}
            navigation
            speed={800}
            loop
            className={styles.customswiper}
            slidesPerView={3}
        >
            {props.images.map((image, index) =>{
                return(
                    <SwiperSlide className={styles.swiperslide}>
                        <img src={image} alt=""/>
                    </SwiperSlide>
                )})}

        </Swiper>
    )
}

export default Caroussel