import { useState, useEffect } from "react"

import NavBar from "../../component/navbar/navbar"
import Footer from "../../component/footer/footer"
import EventCard from "../../component/event_card/event_card"
import ArticleCard from "../../component/article_card/article_card"
import styles from "./actu.module.scss"

import { articles_image } from "../../assets/event"

function ActuPage() {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])


    let events = [
        {
            title: "Inauguration de la box",
            content: "Vous êtes conviés à un verre de l'amitié le Vendredi 9 septembre, à partir de 17h30 jusqu'à 20H. C'est l'occasion pour vous de découvrir la box et de rencontrer le Coach"
        },{
            title: "WOD fondateurs *",
            content: "La Box Crossfit les Herbiers proposera deux WOD le samedi 10 septembre de 10H à 11H et de 11H15 à 12H15. \n *WOD réservés uniquement aux 50 fondateurs de la Box"
        },{
            title: "Ouverture de la BOX",
            content: "La Box Crossfit les Herbiers ouvre ses portes le lundi 12 septembre.\nLet's GO !!!!!!!"
        }
    ]


    let articles = [
        {
            title: "Inauguration",
            date: "09/09/2022",
            content: "La rentrée arrive à grand pas pour Crossfit Les Herbiers. Cette inauguration est l’occasion de rencontrer les pre-inscrits, de présenter la structure et l'équipe, mais également de remercier l'ensemble des personnes ayant participés à la creation de ce projet.",
            image: articles_image[0]
        },{
            title: "NEW : Pour votre recup’ !",
            date: "15/09/2022",
            content: "Vous l’attendiez avec impatience… vous pouvez désormais retrouver nos boissons Nocco, Vitamine Wellington, et barres céréales en vente à l’accueil de la Box. Une petite faim ? Ces produits sont idéals pour faciliter la récupération après séance.",
            image: articles_image[1]
        },{
            title: "Ramène ton pote!",
            date: "15/09/2022",
            content: "Spécial découverte \nPartagez votre passion pour le sport avec votre entourage tous les vendredis de 17h30 à 18h30. Chaque adhérent est accompagné à la séance. Une réservation sur Peppy  = 1 adhérent + 1 pote. Et qui sait, ton pote rejoindra peut être la Team Crossfit Les Herbiers",
            image: articles_image[2]
        }
    ] 

    

    return(
        <div>
            <NavBar/>
            <section className={styles.firstsection}>
                <h3 className={width < 600 ? styles.h3phone : undefined}>événements à la box</h3>
                <div className={width > 1024 ? styles.eventcardcontainer : styles.eventcardcontainertab }>
                    {events.map((event) => {
                        return(<EventCard title={event.title} content={event.content}/>)
                    })}
                </div>
                <h3 className={width < 600 ? styles.h3phone : undefined}>actualité de la box</h3>
                <div className={width > 1024 ? styles.actucardcontainer : width > 600 ? styles.actucardcontainertab : styles.actucardcontainerphone}>
                    {articles.map((article) => {
                        return(<ArticleCard title={article.title} content={article.content} date={article.date} image={article.image}/>)
                    })}
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default ActuPage