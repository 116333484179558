import { useState, useEffect } from "react"

import styles from './event_card.module.scss'

function EventCard(props) {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={width > 600 ? styles.card : styles.cardphone}>
            <div className={styles.header}>
                <h4>{props.title}</h4>
            </div>
            <div className={styles.body}>
                <p>{props.content}</p>
            </div>
        </div>
    )
}

export default EventCard