import {NavLink, useLocation} from 'react-router-dom'
import { FaBars, FaTimes} from "react-icons/fa"
import { useRef, useState, useEffect } from 'react'

import "./navbar.css"
import facebook_white from "../../assets/icons/facebook.svg"
import instagram_white from '../../assets/icons/instagram.svg'
import {logo_yellow} from '../../assets/logo'
import NavInput from '../nav_input/nav_input'

function NavBar() {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    const location = useLocation()
    const navRef = useRef()

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    const background = () => {
        switch(location.pathname) {
            case "/":
                return "acceuil"
            case "/la-box":
                return "actu"
            case "/actu":
                return "actu"
            case "/planning":
                return "planning"
            case "/tarifs":
                return "tarifs"
            case "/contacts":
                return "contacts"
            default:
                return  false
        }
    }

    let activeStyle={
        color: "#F4CB4D"
    }

    return(
        <header className={background()}>
            <div className="content">
                <NavLink to="/"><img src={logo_yellow} alt="Crossfit logo" className="logo"/></NavLink>
                <div className="link">
                    <nav ref={navRef}>
                    {width > 1024
                        ?
                        <div className='container-responsive-nav'>
                            <NavLink style={({isActive}) => isActive ? activeStyle : undefined} to="/">Accueil</NavLink>
                            <NavLink style={({isActive}) => isActive ? activeStyle : undefined} to="/la-box">La Box</NavLink>
                            <NavLink style={({isActive}) => isActive ? activeStyle : undefined} to="/planning">Planning</NavLink>
                            <NavLink style={({isActive}) => isActive ? activeStyle : undefined} to="/tarifs">Tarifs</NavLink>
                            <NavLink style={({isActive}) => isActive ? activeStyle : undefined} to="/contacts">Contacts</NavLink>
                            <div className="socialnetwork">
                                <a href="https://www.instagram.com/crossfit_lesherbiers/?hl=fr" target="_blank" rel="noreferrer"><img src={instagram_white} alt="fb"/></a>
                                <a href="https://www.facebook.com/Crossfit-Les-Herbiers-109780045119793" target="_blank" rel="noreferrer"><img src={facebook_white} alt="insta"/></a>
                            </div>
                        </div>
                        :
                        <div className='container-responsive-nav'>
                            <div className="header-responsive-nav">
                                <img src={logo_yellow} alt="Crossfit logo" className="logo-responsive-nav"/>
                                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                                    <FaTimes/>
                                </button>
                            </div>
                            <div className="content-responsive-nav">
                                <NavInput to="/">Accueil</NavInput>
                                <NavInput to="/la-box">La Box</NavInput>
                                <NavInput to="/planning">Planning</NavInput>
                                <NavInput to="/tarifs">Tarifs</NavInput>
                                <NavInput to="/contacts">Contacts</NavInput>
                                <div className="responsive-socialnetwork">
                                    <a href="https://www.instagram.com/crossfit_lesherbiers/?hl=fr" target="_blank" rel="noreferrer"><img src={instagram_white} alt="fb"/></a>
                                    <a href="https://www.facebook.com/Crossfit-Les-Herbiers-109780045119793" target="_blank" rel="noreferrer"><img src={facebook_white} alt="insta"/></a>
                                </div>
                            </div>
                            <div className="footer-responsive-nav"/>
                        </div>
                    }
                    </nav>
                    <button className="nav-btn" onClick={showNavbar}>
                        <FaBars/>
                    </button>
                </div>
            </div>
        </header>
    )
}

export default NavBar