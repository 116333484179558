import './App.css';

import { Route, Routes } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './component/scroll_to_top';

import AccueilPage from './pages/accueil/accueil'
import LaBoxPage from './pages/la_box/la_box'
import ActuPage from './pages/actu/actu'
import PlanningPage from './pages/planning/planning'
import TarifsPage from './pages/tarifs/tarifs'
import ContactsPage from './pages/contacts/contacts'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<AccueilPage />} />
            <Route path="/la-box" element={<LaBoxPage />} />
            <Route path="/actu" element={<ActuPage />} />
            <Route path="/planning" element={<PlanningPage />} />
            <Route path="/tarifs" element={<TarifsPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
