import { useState, useEffect } from "react"

import styles from './article_card.module.scss'

function ArticleCard (props) {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={width > 600 ? styles.card : styles.cardphone}>
            <div className={styles.header}>
                <h4>{props.title}</h4>
                <h5>{props.date}</h5>
            </div>
            <div className={styles.body}>
                <div className={styles.imgcontainer}>
                    <img src={props.image} alt="actu"/>
                </div>
                <div className={width > 1024 ? styles.textcontainer : styles.textcontainertab}>
                    <p>{props.content}</p>
                </div>
            </div>
        </div>
    )
}

export default ArticleCard