export const caroussel_home_page = [
    require('./car_accueil_1.png'),
    require('./car_accueil_2.png'),
    require('./car_accueil_3.png'),
    require('./car_accueil_4.png'),
    require('./car_accueil_5.png'),
    require('./car_accueil_6.png'),
    require('./car_accueil_7.png'),
    require('./car_accueil_8.png'),
    require('./car_accueil_9.png')
]

export const caroussel_box_page = [
    require('./car_box_1.png'),
    require('./car_box_2.png'),
    require('./car_box_3.png'),
    require('./car_box_4.png'),
    require('./car_box_5.png'),
    require('./car_box_6.png'),
    require('./car_box_7.png'),
    require('./car_box_8.png'),
    require('./car_box_9.png')
]