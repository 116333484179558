import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import styles from "./footer.module.scss"
import facebook_white from "../../assets/icons/facebook.svg"
import instagram_white from '../../assets/icons/instagram.svg'
import {logo_yellow} from '../../assets/logo'
import {circle_footer_left, circle_footer_right} from "../../assets/circle"

function Footer() {

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])
    

    return(
        <div className={styles.container}>
            <div className={styles.background}>
                <div className={styles.left}>
                    {width > 1480 ? <img src={circle_footer_left} alt="" className={styles.circle}/> : <></>}
                </div>
                <div className={styles.right}>
                    {width > 1024 ? <img src={circle_footer_right} alt="" className={styles.circle}/> : <img src={logo_yellow} alt="crossfit-logo" className={styles.logo} />}
                </div>
            </div>
            <div className={width > 1024 ? styles.content : styles.mincontent}>
                {width > 1480 ? <div className={styles.blocleft}/> : <></>}
                <div className={styles.logocontainer}>
                    { width > 1024 ? <img src={logo_yellow} alt="crossfit-logo" className={styles.logo} /> : undefined }
                </div>
                <div className={styles.text}>
                    <h3>une question sur le fonctionnement de crossfit les herbiers ?</h3>
                    <h4>Nous sommes là pour répondre à vos interrogations, alors n'hésitez pas à nous contacter !</h4>
                    <div className={styles.subtitle}>adresse</div>
                    <div className={styles.contactfield}>24 Av. de l'Arborescente<br/>Parc Ekho 4<br/>85500 Les Herbiers</div>
                    <div className={styles.subtitle}>téléphone</div>
                    <div className={styles.contactfield}>07 85 61 24 22</div>
                    <div className={styles.socialnetwork}>
                        <a href="https://www.instagram.com/crossfit_lesherbiers/?hl=fr" target="_blank" rel="noreferrer"><img src={instagram_white} alt="fb"/></a>
                        <a href="https://www.facebook.com/Crossfit-Les-Herbiers-109780045119793" target="_blank" rel="noreferrer"><img src={facebook_white} alt="insta"/></a>
                    </div>
                    <div className={styles.credentials}>
                        <div className={styles.design}>
                            <div className={styles.work}>design: </div>
                            <a href="https://www.instagram.com/slalom_design/?hl=fr" target="_blank" rel="noreferrer">Salomé Pineau</a>
                        </div>
                        <div className={styles.real}>
                            <div className={styles.work}>réalisation: </div>
                            <a href="https://www.linkedin.com/in/lucien-girard-988767183/" target="_blank" rel="noreferrer">Lucien Girard</a>
                        </div>
                    </div>
                </div>
                <div className={styles.navigation}>
                    <NavLink to="/">Accueil</NavLink>
                    <NavLink to="/la-box">La Box</NavLink>
                    <NavLink to="/planning">Planning</NavLink>
                    <NavLink to="/tarifs">Tarifs</NavLink>
                    <NavLink to="/contacts">Contacts</NavLink>
                </div>
                <div className={styles.blocright}/>
            </div>
        </div>
    )
}

export default Footer