import { useState, useEffect, useRef } from "react"
import { sendForm } from "@emailjs/browser"

import Footer from "../../component/footer/footer"
import NavBar from "../../component/navbar/navbar"

import styles from "./contacts.module.scss"
import { contact } from "../../assets/repeat"

function ContactsPage() {    

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    const form = useRef()

    const [succesMessage, setSuccesMessage] = useState(false)
    const [messageContent, setMessageContent] = useState("")

    const sendemail = (e) => {
        e.preventDefault()
        console.log(form.current)
        sendForm("service_r5wz12a", "template_pcstao6", form.current, "Ve5CXCvEXAT7-9086")
            .then((result) => {
                console.log(result.text)
                setSuccesMessage(true)
                setMessageContent("Message envoyé. Vous retrouverez dans votre boite mail une confirmation d'envoie")
            }, (error) => {
                console.log(error)
                setSuccesMessage(false)
                setMessageContent("Une erreur s'est produite pendant l'envoye. Veuillez réessayer.")
            })

    }

    return(
        <div>
            <NavBar/>
            <section className={styles.firstsection}>
                <div className={styles.repeatcontainer}>
                    <img src={contact} alt="Contactez-nous" className={width < 600 ? styles.imgphone : undefined}/>
                </div>
                <h2 className={width < 600 ? styles.h2phone : undefined}>formulaire de contact</h2>
                <form ref={form} onSubmit={sendemail} className={width < 700 ? styles.formphone : undefined}>
                    <div className={styles.inputcontainer}>
                        <div className={styles.leftcontainer}>
                            <select name="subject" placeholder="Type de demande">
                                <option value="Demande d'information">Demande d'informations</option>
                                <option value="Inscription">Inscription</option>
                                <option value="DropIn">Faire un drop in</option>
                            </select>
                            <input type="text" name="name" placeholder="Nom"/>
                            <input type="text" name="firstname" placeholder="Prénom"/>
                            <input type="email" name="email" placeholder="e-mail"/>
                        </div>
                        <div className={styles.rigthcontainer}>
                            <textarea name="message" placeholder="Ecrivez votre message ..."/>
                        </div>
                    </div>
                    <button>Envoyer</button>
                </form>
                {messageContent !== "" ? <div className={succesMessage ? styles.displaysuccess : styles.displayerror}><p>{messageContent}</p></div> : undefined }
            </section>
            <section className={width > 600 ? styles.secondsection : styles.secondsectionphone} display="block">
                <div className={styles.mapcontainer}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2726.589608956317!2d-1.0379656841460456!3d46.89112164511929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4806614f191fc835%3A0x6a68071117b676c0!2s24%20Av.%20de%20l&#39;Arborescente%2C%2085500%20Les%20Herbiers!5e0!3m2!1sfr!2sfr!4v1659993239080!5m2!1sfr!2sfr" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Maps"></iframe>
                </div>
                <div className={width > 800 ? styles.content : styles.contenttab}>
                    <h1>Kevin Chevallereau, coach et gérant de Crossfit Les Herbiers</h1>
                    <h2>ADRESSE</h2>
                    <h1>24 Av. de l’Arborescente Parc Ekho 4 85500 Les Herbiers</h1>
                    <h2>TÉLÉPHONE</h2>
                    <h1>07 85 61 24 22</h1>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default ContactsPage