import {NavLink} from 'react-router-dom'

import styles from './nav_input.module.scss'

function NavInput (props) {

    return(
        <NavLink to={props.to} className={styles.container}>
            <h3 to={props.to}> {props.children}</h3>
            <div className={styles.halfarrow}/>
        </NavLink>
    )
}

export default NavInput