import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

import Footer from "../../component/footer/footer"
import NavBar from "../../component/navbar/navbar"
import Caroussel from "../../component/caroussel/caroussel"
import styles from "./la_box.module.scss"
import { logo_yellow } from "../../assets/logo"
import { circle_slogan } from "../../assets/circle"
import { felix_discution, kev_teach } from "../../assets/la_box"
import { caroussel_box_page } from "../../assets/caroussel"

function LaBoxPage() {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }
      
        window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    return(
        <div>
            <NavBar/>
            <section className={styles.firstsection}>
                <div className={ width > 1024 ? styles.logocontainer : width > 600 ? styles.logocontainertab : styles.logocontainerphone}>
                    <img src={logo_yellow} alt="logo" className={styles.logo} />
                </div>
                <div className={styles.circlecontainer}>
                    <img src={circle_slogan} alt="" className={styles.circle} />
                </div>
            </section>
            <section className={styles.secondsection}>
                <div className={styles.imgcontainer} >
                    <img src={felix_discution} alt="" />
                </div>
                <div className={width > 1024 ? styles.content : width > 600 ? styles.contenttab : styles.contentphone}>
                    <h2>ce que nous proposons :</h2>
                    <p>Une box composée d’un large espace de pratique, d’une structure centrale, du matériel de gymnastique et d’haltérophilie pour réaliser nos cours (W.O.D., Open Gym, conditionning, haltérophilie).</p>
                    <p>Des cours de sport accessibles à tous : nous prenons en compte vos aptitudes, débutants ou expérimentés, chacun progresse à son rythme. Nous mettons au service notre savoir-faire dans la réalisation de vos objectifs.</p>
                    <p>Prendre 1h de votre temps pour faire une pause dans votre quotidien, décompresser, progresser, performer… Notre rôle : vous accompagner vers le dépassement de soi !</p>
                    <p>CrossFit Les Herbiers c’est une communauté forte, de l’engouement et du partage : Une source pour votre motivation.</p>
                </div>
            </section>
            <Caroussel images={caroussel_box_page}/>
            <section className={width > 600 ? styles.forthsection : styles.forthsectionphone}>
                <div className={styles.imagecontainer}>
                    <img src={kev_teach} alt="kev" />
                </div>
                <div className={width > 1024 ? styles.content : styles.contenttab}>
                    <div>
                        <h1>kevin chevallereau</h1>
                        <h2>Coach et gérant</h2>
                    </div>
                    <p>Sportif depuis toujours, il commence par le foot, puis l’endurance, la musculation, avant de se professionnaliser dans l’activité de la forme. Après avoir obtenu son diplôme de coach sportif à l’IRSS de Cholet, il s’expérimente dans les centres de remise en forme. La découverte du CrossFit en 2017 renforce son intérêt pour la santé du corps. Il obtient son Level 1 en 2021 afin de se consacrer entièrement au CrossFit. Kévin met au service de la Box, ses compétences de coach et son expérience de crossfiteur… Dans la bonne humeur et la convivialité !</p>
                    <h3>« J’entreprends par conviction : le CrossFit est la solution pour renforcer et préserver sa santé »</h3>
                </div>
            </section>
            <section className={width > 1024 ? styles.fifthsection : width > 450 ? styles.fifthsectiontab : styles.fifthsectionphone }>
                <h2 >ENVIE DE FAIRE PARTIE DE L’AVENTURE CROSSFIT LES HERBIERS ?</h2> 
                <NavLink to="/contacts">Remplir le formulaire de contact</NavLink>
            </section>
            <Footer/>
        </div>
    )
}

export default LaBoxPage